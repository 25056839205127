import React, { useState, useEffect } from "react";

function App() {
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);
  const audio = new Audio("/beep.mp3");

  useEffect(() => {
    let timer = null;
    let timer2 = null;

    if (isActive && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => {
          // Reproduce el audio en el segundo 4
          if (prevTime === 6) {
            audio.play();
          }
          if (prevTime <= 1) {
            // Restablece el contador a cero
            setIsActive(false);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    } else if (!isActive && timeLeft === 0) {
      // Agrega un delay de 1 segundo antes de reiniciar
      timer2 = setTimeout(() => {
        const totalSeconds = hours * 3600 + minutes * 60 + seconds;
        setTimeLeft(totalSeconds);
        setIsActive(true);
      }, 1000);
    }

    return () => {
      clearInterval(timer);
      clearInterval(timer2);
    };
  }, [isActive, timeLeft, audio]);

  const handleStart = () => {
    const totalSeconds = hours * 3600 + minutes * 60 + seconds;
    setTimeLeft(totalSeconds);
    setIsActive(true);
  };

  const handleStop = () => {
    setIsActive(false);
  };

  const handleReset = () => {
    setIsActive(false);
    setTimeLeft(0);
    setHours(0);
    setMinutes(0);
    setSeconds(0);
  };

  return (
    <div className="flex flex-col items-center  h-full bg-gray-100">
      <a href="https://www.instagram.com/sabotajecronos/">
        <img src="/sabotajecronos-black.png" alt="logo" className="p-5" />
      </a>
      <div className="md:text-[128px] text-[160px] font-semibold mt-14 mb-40 text-gray-800 ">
        {String(Math.floor(timeLeft / 3600)).padStart(2, "0")}:
        {String(Math.floor((timeLeft % 3600) / 60)).padStart(2, "0")}:
        {String(timeLeft % 60).padStart(2, "0")}
      </div>
      <div>
        <div className="mb-4">
          <label>Hour</label>
          <input
            type="number"
            value={hours}
            onChange={(e) =>
              setHours(Math.max(0, parseInt(e.target.value) || 0))
            }
            placeholder="Hour"
            className="border border-gray-300 rounded p-2 mx-1 w-16"
          />
          <label>Minutes</label>
          <input
            type="number"
            value={minutes}
            onChange={(e) =>
              setMinutes(Math.max(0, parseInt(e.target.value) || 0))
            }
            placeholder="Minutes"
            className="border border-gray-300 rounded p-2 mx-1 w-16"
          />
          <label>Seconds</label>
          <input
            type="number"
            value={seconds}
            onChange={(e) =>
              setSeconds(Math.max(0, parseInt(e.target.value) || 0))
            }
            placeholder="Seconds"
            className="border border-gray-300 rounded p-2 mx-1 w-16"
          />
        </div>
        <div className="mb-10 flex justify-center">
          <button
            onClick={handleStart}
            className="bg-blue-500 text-white px-4 py-2 rounded mr-2"
          >
            Play
          </button>
          <button
            onClick={handleStop}
            className="bg-red-500 text-white px-4 py-2 rounded mr-2"
          >
            Stop
          </button>
          <button
            onClick={handleReset}
            className="bg-gray-500 text-white px-4 py-2 rounded"
          >
            Reset
          </button>
        </div>
      </div>
    </div>
  );
}

export default App;
